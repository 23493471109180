import { useEffect } from 'react';
import { getParameterByName } from '../utils/geParametersByName';

const useTagManager = () => {
  useEffect(() => {
    let affTag = getParameterByName('aff');
    let artTag = getParameterByName('art');
    let cusTag = getParameterByName('cus');
    let staticTag = 'Home';

    let currentTags = document.cookie.split('; ').find(row => row.startsWith('tags'))?.split('=')[1];
    currentTags = currentTags ? JSON.parse(decodeURIComponent(currentTags)) : [];

    if (artTag) currentTags.push({ tag: artTag, timestamp: new Date().toISOString() });
    if (cusTag) currentTags.push({ tag: cusTag, timestamp: new Date().toISOString() });
    if (affTag) currentTags.push({ tag: affTag, timestamp: new Date().toISOString() });
    if (staticTag) currentTags.push({ tag: staticTag, timestamp: new Date().toISOString() });

    document.cookie = `tags=${encodeURIComponent(JSON.stringify(currentTags))};path=/;domain=.vectorvault.io;secure`;
  }, []);
};

export default useTagManager;
