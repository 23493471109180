/* global Plotly */
import React, { useState, useEffect } from 'react';
import Modal from './RoodModal';
import './Dashboard.css'; 
import styled from 'styled-components';
import { setCookie, getCookie, removeCookie } from '../cookieUtils';
import VectorVault from 'vectorvault';

// Styled Components
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: fixed; // Ensures that your column widths are respected

  th, td {
    padding: 10px;
    border: 1px solid ${props => props.isDarkMode ? '#333' : '#e0e0e0'};
    text-align: center;
    vertical-align: middle;
    overflow: hidden; // Hide overflow content
    text-overflow: ellipsis; // Show ellipsis for overflow text
    white-space: nowrap; // Keep the content in a single line
    color: ${props => props.isDarkMode ? '#ddd' : '#333'}; // Light text for dark mode
  }

  tr:nth-child(even) {
    background-color: ${props => props.isDarkMode ? '#3d424e' : '#f7f7f7'};
  }

  tr:hover {
    background-color: ${props => props.isDarkMode ? '#58688b' : '#eef6fc'};
  }

  th:nth-child(1), td:nth-child(1) {
    width: ${props => props.edit ? '10%' : '5%'}; // Assigning width for the 'Item' column
  }

  th:nth-child(2), td:nth-child(2) {
    // Styles specific to 'Created' column
    width: 15%; // Assigning width for the 'Created' column
    padding-top: 7px;
    padding-bottom: 5px;
    white-space: normal; // This will allow the text to wrap
    word-wrap: break-word; // To ensure that words break properly
  }

  th:nth-child(3), td:nth-child(3) {
    width: ${props => props.edit ? '60%' : '95%'}; // Assigning width for the 'Data' column
  }

  th:nth-child(4), td:nth-child(4) {
    width: 8%; // Assigning width for the 'Delete' column
  }

  // Media query for mobile devices
  @media (max-width: 768px) {
    th, td {
      padding: 5px; // Reduced padding
      font-size: .9rem; // Reduced font size
    }

    th:nth-child(1), td:nth-child(1) {
      width: 10%; 
    }
  
    th:nth-child(2), td:nth-child(2) {
      display: none; // width: 10%; 
    }
  
    th:nth-child(4), td:nth-child(4) {
      width: 10%; 
    }
  }
`;

const StyledTableContainer = styled.div`
    padding: 20px;

    @media (max-width: 768px) {
    padding: 0px; // Reduced padding for mobile view
    }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const PaginationButton = styled.button`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
    margin-right: 5px; // Less margin on mobile
  }
`;

const PageIndicator = styled.span`
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
  }
`;

const JumpToInput = styled.input`
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 12px; // Smaller text on mobile
    padding: 5px; // Smaller padding on mobile
  }
`;

const PaginationSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller text on mobile
    margin-top: 10px; // Add space between sections on mobile
  }
`;

const transformData = (incomingData) => {
    if (!incomingData || !Array.isArray(incomingData)) return [];
    return incomingData.map(item => ({
        id: item.metadata.item_id,
        name: item.metadata.name,
        created: item.metadata && (item.metadata.created || item.metadata.created_at) 
                 ? (formatTimestamp(item.metadata.created) || formatTimestamp(item.metadata.created_at)).split('T')[0] 
                 : '',
        data: item.data
    }));
};

const fetchItems = async ( itemIds, Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      const items = await vectorVault.getItems(itemIds)
      return transformData(items); // Access the 'results' key and return transformed data
    } catch (error) {
        console.error("There was an error fetching the data:", error);
        throw error; // We re-throw the error so it can be caught and handled by the caller
    }
};

const fetchTotalItems = async ( Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey)
      const totalItems = await vectorVault.getTotalItems()
      return totalItems
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const fetchSearchResults = async ( searchQuery, Ampersandposium, userEmail, userKey, AIKeyVal ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal)
      const results = await vectorVault.getSimilar({ text: searchQuery })
      return transformData(results.results); // Assuming the response JSON directly contains the total items count
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

const deleteItem = async ( itemId, Ampersandposium, userEmail, userKey ) => {
    try {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
      await vectorVault.deleteItems(itemId)
      return true; 
    } catch (error) {
      console.error("There was an error fetching the total items count:", error);
      throw error; // Re-throw the error so it can be caught and handled by the caller
    }
};

function formatTimestamp(timestamp) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const options = { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  return date.toLocaleString('en-US', options);
}

// Main Component
function DatabaseTable({ Ampersandposium, reloadData, userEmail, userKey, AIKeyVal, isDarkMode, wait, styles, edit, del }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState('');
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentCreatedDate, setCurrentCreatedDate] = useState('');
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(!isNaN(parseInt(getCookie('dbCurrentPage'))) ? parseInt(getCookie('dbCurrentPage')) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [databaseData, setDatabaseData] = useState([])
  const [totalItems, setTotalItems] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [desiredPage, setDesiredPage] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [maphtml, setMaphtml] = useState(false);
  const [showVisualization, setShowVisualization] = useState(false);
  const [vizLoading, setVizLoading] = useState(false);
  const [highlightId, setHighlightId] = useState(null);
  
  const getMap = async (Ampersandposium, userEmail, userKey ) => {
    try {
        const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey);
        const highlightIdInt = highlightId !== null ? parseInt(highlightId, 10) : null;
        const html = await vectorVault.fetch3DMap(highlightIdInt); // Pass the highlight_id
        return html.html; // Access the 'results' key and return transformed data
    } catch (error) {
        console.error("There was an error fetching the data:", error);
        throw error; // We re-throw the error so it can be caught and handled by the caller
    }
  };

  const openInNewWindow = () => {
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      // Write the HTML content into the new window
      newWindow.document.write(maphtml);
  
      // Optionally, add some basic styling or scripts
      newWindow.document.write('<style>body { margin: 0; }</style>'); // Example styling
  
      // Close the document to apply the content
      newWindow.document.close();
    }
  };

  // Toggle visualization and fetch data if necessary
  const toggleVisualization = async (shouldClose = 1) => {
    if (!showVisualization || shouldClose === 0) {
      setVizLoading(true)
      try {
        const mapData = await getMap(Ampersandposium, userEmail, userKey);
        setMaphtml(mapData);
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
      setShowVisualization(true);
      setVizLoading(false)
    } else {
      setShowVisualization(false);
      setVizLoading(false)
    }
  };

  useEffect(() => {
    if (!maphtml || !showVisualization) return;
  
    const plotlyDiv = document.getElementById('plotly-map');
    plotlyDiv.innerHTML = maphtml; // Directly set maphtml to plotlyDiv
  
    // Function to dynamically load a script and return a promise
    const loadScript = src => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };
  
    // Identify all scripts within maphtml
    const scripts = Array.from(plotlyDiv.getElementsByTagName('script'));
  
    // Load external scripts first, especially Plotly if it's not already loaded
    const externalScriptsLoadPromises = scripts
      .filter(script => script.src)
      .map(script => loadScript(script.src));
  
    // Wait for all external scripts to load
    Promise.all(externalScriptsLoadPromises)
      .then(() => {
        // Now safe to execute inline scripts and resize Plotly graph
        scripts.filter(script => !script.src).forEach(script => {
          const inlineScript = document.createElement('script');
          inlineScript.text = script.text;
          document.body.appendChild(inlineScript).parentNode.removeChild(inlineScript);
        });
  
        // Resize Plotly graphs, if any
        const plotlyGraphDiv = plotlyDiv.querySelector('.plotly-graph-div');
        if (plotlyGraphDiv) {
          plotlyGraphDiv.style.height = '700px'; // Set desired height
          window.Plotly.Plots.resize(plotlyGraphDiv);
        }
      })
      .catch(error => console.error("Error loading scripts:", error));
  
  }, [maphtml, showVisualization]);


  // Consolidate data fetching logic
  useEffect(() => {
      if (!Ampersandposium || wait) return;

      const fetchData = async () => {
          try {
              const total = await fetchTotalItems(Ampersandposium, userEmail, userKey, AIKeyVal);
              setTotalItems(total);
              setItemsPerPage(total < 10 ? total : 10);

              const startId = (currentPage - 1) * itemsPerPage;
              const endId = Math.min(startId + itemsPerPage, total);
              const currentPageItemIds = Array.from({ length: endId - startId }, (_, i) => startId + i);

              const dataItems = await fetchItems(currentPageItemIds, Ampersandposium, userEmail, userKey, AIKeyVal);
              setDatabaseData(dataItems);
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setIsDataLoading(false);
          }
      };

      fetchData();
  }, [Ampersandposium, userEmail, userKey, AIKeyVal, currentPage, itemsPerPage, wait, reloadData]);

  const handlePage = (newPage) => {
    setCookie('dbCurrentPage', newPage)
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (totalItems === 0) {
      setIsDataLoading(false)
    }}, [totalItems])

  const handleDataClick = (item) => {
      setCurrentItemId(item.id);
      setCurrentCreatedDate(item.created);
      setName(item.name);
      setCurrentData(item.data);
      setIsModalOpen(true);
  };

  const handleSearch = async () => {
      try {
        setIsDataLoading(true);
        const searchResults = await fetchSearchResults(searchQuery, Ampersandposium, userEmail, userKey, AIKeyVal);
        setDatabaseData(searchResults);
        setIsDataLoading(false);
        removeCookie('dbCurrentPage')
      } catch (error) {
        console.error("Error searching for:", searchQuery, error);
      }
    };      

  const handleDelete = async (itemId) => {
      try {
          setIsDataLoading(true)
          await deleteItem( itemId, Ampersandposium, userEmail, userKey );
          setCurrentPage(prevPage => prevPage);
          setIsDataLoading(false)
          alert("Item deleted.")
          window.location.reload();
      } catch (error) {
          console.error(`Error deleting item with ID: ${itemId}`, error);
      }
  };

  return (
      <StyledTableContainer>
          {isDataLoading ? (
              // Render the loading spinner when isDataLoading is true
              <div className="loading-spinner" style={{ display: 'block' }}>
                  <svg viewBox="0 0 50 50">
                      <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                  </svg>
              </div>
          ) : (
              <>
          <h2 style={{marginTop: "40px"}}>
          Database: <span>{totalItems} items</span>
          <div style={{ display: 'block', width: '100%', marginBottom: '10px' }}>
              <input
                  className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
                  type="text"
                  placeholder="Search the database with a question or statement..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{ width: 'calc(100%)', fontSize: "1rem", paddingTop: '10px', paddingBottom: '10px', marginLeft: "0px"}}
              />
              <button onClick={handleSearch} style={{...styles.button}}>Search</button>
          </div>
          </h2>
          <StyledTable isDarkMode={isDarkMode} edit={edit} >
              <thead>
                  <tr>
                      <th>Item</th>
                      {edit && <th>Created</th>}
                      <th>Data</th>
                      {del && <th>Delete</th>}
                  </tr>
              </thead>
              <tbody>
                  {databaseData.map((item, index) => (
                      item ? (
                          <tr key={index}>
                              <td>{item.id}</td>
                              {edit && <td>{item.created}</td>}
                              <td style={{ textAlign: 'left' }}>
                                  <span onClick={() => handleDataClick(item)} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                      {item.data && item.data.length > 50 ? item.data.substring(0, 200) + "..." : item.data}
                                  </span>
                              </td>
                                  {del && (
                                      <td>
                                          <span 
                                              onClick={() => handleDelete(item.id)}
                                              style={{ cursor: 'pointer', color: 'red' }}
                                          >
                                              🗑️
                                          </span>
                                      </td>
                                  )}
                          </tr>
                      ) : null
                  ))}
              </tbody>
          </StyledTable>
          <Modal 
              isOpen={isModalOpen} 
              onClose={() => setIsModalOpen(false)} 
              itemId={currentItemId} 
              createdDate={currentCreatedDate} 
              name={name} 
              content={currentData} 
              Ampersandposium={Ampersandposium} 
              isDarkMode={isDarkMode}
              userEmail={userEmail}
              userKey={userKey}
              AIKeyVal={AIKeyVal}
              styles={styles}
              edit={edit}
          />
          
          {/* Pagination controls */}
          <PaginationContainer>
          <div>
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginLeft: "0px" }}
              onClick={() => handlePage(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
              >
              Previous
              </PaginationButton>
              <PageIndicator>
              Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)}
              </PageIndicator>
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginLeft: "0px" }}
              onClick={() => handlePage(Math.min(currentPage + 1, Math.ceil(totalItems / itemsPerPage)))}
              disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
              >
              Next
              </PaginationButton>
          </div>
          <PaginationSection>
              <a style={{ marginTop: '0px' }}>Page: </a>
              <JumpToInput 
              className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
              style={{ marginTop:"-0px", maxWidth: "75px" }} 
              type="number" 
              value={desiredPage}
              onChange={e => setDesiredPage(Number(e.target.value))}
              />
              <PaginationButton 
              className={ isDarkMode ? 'btn-dark' : 'btn-light' }
              style={{ marginTop:"2px", marginRight: "0px" }} 
              onClick={() => handlePage(Math.max(1, Math.min(desiredPage, Math.ceil(totalItems / itemsPerPage))))}
              >
              Go
              </PaginationButton>
          </PaginationSection>
          </PaginationContainer>

  
          {/* Bottom of Page Spacing */}
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          </>
      )}

      <button 
          onClick={() => toggleVisualization()}
          style={{...styles.button, margin: "20px 0"}}
      >
          {showVisualization ? "Close Visualization" : "Visualize Data"}
      </button>
      
      {vizLoading && // Render the loading spinner when isDataLoading is true
        <div className="loading-spinner" style={{ display: 'block' }}>
            <svg viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
            </svg>
        </div> 
      }
      
      {showVisualization && (
        <div style={{ alignItems: 'left', margin: '20px 0' }}>
          <div style={{ marginLeft: '2px' }}>
              <a>Highlight Item:</a>
          </div>
          <div style={{ marginTop: '10px'}}>
              <input
                  className={isDarkMode ? 'sleek-input-dark' : 'sleek-input-light'}
                  type="text"
                  value={highlightId}
                  onChange={(e) => setHighlightId(e.target.value)}
                  placeholder="Item ID"
                  style={{ maxWidth: '80px', fontSize: "1rem", marginLeft: '0px' }}
              />
              <button
                  onClick={() => toggleVisualization(0)}
                  className={isDarkMode ? 'btn-dark' : 'btn-light'}
                  style={{ marginLeft: '6px' }}
              >
                  Regenerate
              </button>
          </div>
        </div>
      )}

      {showVisualization && (
        <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "10px" }}>
          <div 
              id="plotly-map"
              className="map-container" 
              style={{ width: '100%', height: '100%' }} // Adjust height as needed
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'left' }}>
          <button 
            onClick={openInNewWindow} 
            className='link-button'
            style={{ margin: "20px 0" }}
            >
            View in New Tab
          </button>
        </div>
        </>
      )}

      </StyledTableContainer>
  );
}


export default DatabaseTable;
