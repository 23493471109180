// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from './dashboard/Dashboard'; 
import { postMail } from './api/credationStation';
import useTagManager from './hooks/useTagManager';
import { darkStyles, lightStyles } from './styles.js';
import Database from './dashboard/Database.js';

function App() {
  const str2Bool = (stringValue) => {
    return stringValue === 'True';
  };  
  const isDarkMode = str2Bool(window.env.DARK);
  const userEmail = 'john.rood@decision.com';
  const title = 'Haddox AI';
  const description = null;
  const forwardslash = window.env.FORWARDSLASH;
  const [Ampersandposium, setAmpersandposium] = useState('haddox');
  const chat = true;
  const data = true;
  const edit = true;
  const del = true;
  const gp4 = true;
  const change = true;
  const apiVisible = str2Bool(window.env.APIVISIBLE);
  const [AIKeyVal, setAIKeyVal] = useState('');
  const [userKey, setUserKey] = useState(false);
  const [wait, setWait] = useState(true); 
  const styles = isDarkMode ? darkStyles : lightStyles;

  // if apikey visible, do not pull their api key - leave blank
  useTagManager();

  useEffect(() => {
      document.title = title;
  }, []);

  const handleCredGet = useCallback(async () => {
    try {
      setWait(true)
      const result = await postMail(userEmail);
      setUserKey(result.key); 
      setWait(false)
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  }, [userEmail, setUserKey]); // Include all dependencies
  
  useEffect(() => {
    if (userEmail) {
      handleCredGet();
    }
  }, [userEmail, handleCredGet]); 

  return (
    <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <HashRouter>
        <div className="Content"> 
          <Routes>
            {chat && !data && (
              <Route path='/' element={<Dashboard Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} styles={styles} AIKeyVal={AIKeyVal} setAIKeyVal={setAIKeyVal} wait={wait} data={data} edit={edit} del={del} gp4={gp4} apiVisible={apiVisible} change={change} description={description} title={title} />} />
            )}

            {chat && data && (
              <Route path='/' element={<Dashboard Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} styles={styles} AIKeyVal={AIKeyVal} setAIKeyVal={setAIKeyVal} wait={wait} data={data} edit={edit} del={del} gp4={gp4} apiVisible={apiVisible} change={change} description={description} title={title} />} />
            )}

            {!chat && data && (
              <Route path='/' element={<Database Ampersandposium={Ampersandposium} setAmpersandposium={setAmpersandposium} userEmail={userEmail} userKey={userKey} isDarkMode={isDarkMode} styles={styles} AIKeyVal={AIKeyVal} setAIKeyVal={setAIKeyVal} wait={wait} edit={edit} del={del} apiVisible={apiVisible} description={description} title={title} />} />
            )}
          </Routes>
        </div>
      </HashRouter> 
      {/* <p>
        <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">
          Powered by Vector Vault
        </a>
      </p> */}
    </div> 
  );
}

export default App;
