import React, { useState, forwardRef, useCallback, useEffect } from 'react';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './DataTable';
import { getCookie, setCookie } from '../cookieUtils.js';
import { secureSessionStore, secureSessionRetrieve, secureSessionDelete } from '../api/secureStore.js';
import './Dashboard.css';


export function ChatContainer({ isDarkMode, isMobile, chatHistory, setChatHistory, windowWidth, Ampersandposium, reloadData, userEmail, userKey, AIKeyVal, setAIKeyVal, wait, styles, edit, del, data, apiVisible, change, setNotificationMessage, gpt4 }, ref) {
  const [chatMessage, setChatMessage] = useState('');
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [summary, setSummary] = useState(false);
  const [getContext, setGetContext] = useState(true);
  const [nContext, setNContext] = useState(4);
  const [historySearch, setHistorySearch] = useState(false);
  const [model, setModel] = useState(getCookie('model') ? getCookie('model') : 'gpt-3.5-turbo');
  const [prevModel, setPrevModel] = useState(getCookie('model') ? getCookie('model') : 'gpt-3.5-turbo');
  const [tempMessage, setTempMessage] = useState('');
  const [includeContextMeta, setIncludeContextMeta] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [stateSet, setStateSet] = useState(false)
  
  useEffect(() => {
  if (gpt4 === true && stateSet === false) { // check if GPT4 should be the defualt model, set if true
    setModel('gpt-4');
    setStateSet(true)
    } 
  })

  const handleKeytrieve = async () => {
    const aiKey = await secureSessionRetrieve(userEmail);
    return aiKey
  };
  const handleInputChange = async (event) => {
    const inputValue = event.target.value;
    setAIKeyVal(inputValue);
    setDisplayValue('*'.repeat(inputValue.length));
  };

  const handleAPISave = async () => {
    await secureSessionStore(userEmail, AIKeyVal);
  };

  const handleAPIDelete = async () => {
    setAIKeyVal('');
    setDisplayValue('');
    await secureSessionDelete(userEmail);
  };

  useEffect(() => {
    const fetchAIKey = async () => {
      try {
        const aiKey = await handleKeytrieve();
        if (aiKey) {
          setAIKeyVal(aiKey);
          setDisplayValue('*'.repeat(aiKey.length));
        }
      } catch (error) {
        console.error('Error fetching AI key:', error);
      }
    };
  
    fetchAIKey();
  }, []);

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }
  
  const showChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(true);
  }, [setChatIsLoading]);
  
  const hideChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(false);
  }, [setChatIsLoading]);
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { message, isUserMessage },
    ]);
  }, [setChatHistory]);

  const removeLastChatMessage = useCallback(() => {
    setChatHistory((prevHistory) => {
      // Check if the last message is a user message
      if (prevHistory.length > 0 && !prevHistory[prevHistory.length - 1].isUserMessage) {
        // Remove the last item from the array if it's not a user message
        const updatedHistory = prevHistory.slice(0, prevHistory.length - 1);
        return updatedHistory;
      }
      // Return the original chat history if the last message is a user message
      return prevHistory;
    });
  }, [setChatHistory]);
  
  function renderChatSegment(segment, index, isUserMessage) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      const code = segment.slice(3, -3);
      const type = detectLanguage(code)
      
      const increaseBy = 1/(windowWidth/500) * 6
      
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
        <label>{type}</label>
        <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
        {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <div key={index} id="chat-history" style={isUserMessage ? styles.userMessage : styles.botMessage}>
          {segment}
        </div>
      );
    }
  }
  

  useEffect(() => {
    if ( model !== prevModel ) {
      const yo = `Using model: ${model}`
      setCookie('model', model)
      setPrevModel(model)
      setNotificationMessage(yo)
    }
  }, [model]);


  const sendChatMessage = async () => {
    if (!chatMessage) return;
    appendChatMessage(chatMessage, true);
    showChatLoadingSpinner();
    let pastChatMessage = chatMessage
    let metatag, metatag_prefixes, metatag_suffixes, returnContext;
    setChatMessage('');
    
    if (pastChatMessage.trim().endsWith('**')) {
      metatag = ['item_id'];
      metatag_prefixes = ['\n**Item ID:'];
      metatag_suffixes = ['\n'];
      returnContext = true;
    } else {
      metatag = [];
      metatag_prefixes = [];
      metatag_suffixes = [];
      returnContext = false;
    }
    
    let fullHistory = chatHistory.map((item) => {
      return item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`;
    }).join('\n');

    let buffer = '';
    try {
        fetch('https://api.vectorvault.io/stream', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            vault: Ampersandposium,
            user: userEmail,
            api_key: userKey,
            openai_key: AIKeyVal,
            text: pastChatMessage,
            history: fullHistory,
            summary: summary,
            get_context: getContext,
            n_context: nContext,
            return_context: returnContext,
            history_search: historySearch,
            model: model,
            include_context_meta: includeContextMeta,
            metatag: metatag,
            metatag_prefixes: metatag_prefixes,
            metatag_suffixes: metatag_suffixes,
          })
        }).then(response => {
          let reader = response.body.getReader();
          let decoder = new TextDecoder();
  
          return reader.read().then(function processText({ done, value }) {
            if (done) return;
            let result = decoder.decode(value, {stream: true});
  
            if (result.includes('data: ')) {
              if (showChatLoadingSpinner) {
                hideChatLoadingSpinner();
              }
              let dataBlocks = result.split('data: ').filter(Boolean);
              dataBlocks.forEach((dataBlock) => {
                buffer += dataBlock; // Append new data to buffer
                try {
                  // Try to parse the entire buffer each time new data comes
                  let parsedData = JSON.parse(buffer);
                  let formattedData = parsedData.data;
                  
                  // If parsing is successful, the buffer contains a complete JSON object
                  // Clear the buffer for the next object
                  buffer = '';
                  
                  // Process the complete object
                  if (formattedData === '!END') {
                    reader.cancel();
                    formattedData = '';
                    setTempMessage(tempMessage => {
                      const newChatHistory = [...chatHistory, { message: pastChatMessage, isUserMessage: true }, { message: tempMessage, isUserMessage: false }];
                      localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
                      setChatHistory(newChatHistory);
                      return '';
                    });
                  } else {
                    setTempMessage(tempMessage => {
                      let updatedTempMessage;
                      if (formattedData === '!END') {
                        updatedTempMessage = `${tempMessage}`;
                      } else {
                        updatedTempMessage = `${tempMessage}${formattedData}`;
                        removeLastChatMessage();
                        appendChatMessage(updatedTempMessage, false);
                      }
                      return updatedTempMessage;
                    });
                  }
                } catch (err) {
                  // If parsing fails, the JSON object is probably incomplete and has been split between data events
                  // The catch block will catch the error and the buffer will keep the data until the rest of it arrives in the next event(s)
                  console.log('Incomplete JSON object in buffer. Waiting for more data.');
                }
              });
            }
            return reader.read().then(processText);
          });
          
        }).catch(err => {
          console.error(err);
        });
    } catch (error) {
      appendChatMessage(error.message, false);
      localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }]));
    } finally {
    }
  };
  
  const clearChat = useCallback(() =>  {
    setChatHistory([])
    localStorage.removeItem("chatHistory");
  }, [setChatHistory]);
  
  const handleTextareaKeydown = (e) => {
    if  (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  };
  
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            {apiVisible &&
              <>
            <h3 style={{ ...styles.responseContainerH3, marginTop: "2px", marginBottom: "0" }}>OpenAI API:</h3>
            
            <input 
                type="text"
                placeholder="Your OpenAI API key here"
                value={displayValue}
                onChange={handleInputChange}
                style={{ ...styles.apiInput }} 
            />
            </>
            }
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

            <button 
                id="clear-chat" 
                className="link-button" 
                onClick={clearChat} 
                style={{ marginTop: "0px", marginRight: "2px" }}
            >
                Clear
            </button>
          </div>
        </div>

        <div style={{ marginTop: "10px" }}></div>
        {chatHistory.flatMap((item, index) => {
            const segments = item.message.split(/(```[\s\S]*?```)/);
            return segments.map((segment, segmentIndex) =>
              renderChatSegment(segment, `${index}-${segmentIndex}`, item.isUserMessage)
            );
          })}
          <div style={{ display: 'flex', alignItems: 'left', margin: "5px" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.yourmsgLabel }}>
                <label htmlFor="chat-message">Chat:</label>
                { change &&
                  <>
                    <select
                      style={{ marginLeft: "10px" }}
                      className={isDarkMode ? 'sleek-select-dark' : 'sleek-select-light'}
                      value={model} // Use the model state as the value of the select
                      onChange={(e) => setModel(e.target.value)} // Update model state on change
                    >
                      {/* Predefined list of models as options */}
                      <option value="gpt-4-turbo-preview">gpt-4-turbo-preview</option>
                      <option value="gpt-4-1106-preview">gpt-4-1106-preview</option>
                      <option value="gpt-4">gpt-4</option>
                      <option value="gpt-4-32k">gpt-4-32k</option>
                      <option value="gpt-4-0613">gpt-4-0613</option>
                      <option value="gpt-3.5-turbo">gpt-3.5-turbo</option>
                      <option value="gpt-3.5-turbo-16k">gpt-3.5-turbo-16k</option>
                      <option value="gpt-3.5-turbo-instruct">gpt-3.5-turbo-instruct</option>
                      <option value="gpt-3.5-turbo-0613">gpt-3.5-turbo-0613</option>
                      <option value="gpt-3.5-turbo-0301">gpt-3.5-turbo-0301</option>
                    </select>
                  </>
                }
              </div>
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input 
                type="checkbox" 
                id="vault-reply" 
                style={{ marginLeft: "15px", marginTop: "22px" }}
                onChange={(e) => setGetContext(e.target.checked)}
                defaultChecked
                title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context."
              />
              <label style={{ ...styles.text, marginLeft: "6px", marginTop: "22px", fontSize: ".9rem" }} htmlFor="vault-reply" title="Check to use Vault context in the reply (default). Uncheck to use the LLM without any Vault context.">
                get context
              </label>
            </div>
          </div>
        <textarea //
          placeholder={`Type your message here...`}
          id="chat-message"
          rows={isMobile ? "8" : "5" }
          style={{ ...styles.textarea, paddingLeft: "8px"}}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}> 
          <button id="chat-submit" style={{ ...styles.button }} onClick={sendChatMessage}>Send</button>
        </div>
        <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isChatLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
          </svg>
        </div>
        <div style={{ marginTop: "100px" }}></div>
        {data && <DataTable Ampersandposium={Ampersandposium} reloadData={reloadData} userEmail={userEmail} userKey={userKey} AIKeyVal={AIKeyVal} isDarkMode={isDarkMode} wait={wait} styles={styles} edit={edit} del={del} />}
      </div>
    </>
  );
}

export default forwardRef(ChatContainer);
/** 
magick -background none -size 960x960 xc:black ( xc:darkred -duplicate 1 +append ) xc:gold ( xc:teal -duplicate 2 +append ) -modulate 100,100,"%[fx:rand()*200]" xc:white -scale x1 +append -write mpr:clut +delete radial-gradient: mpr:clut -clut -scale 100x4% -wave "%[fx:rand()*24+24]"x"%[fx:w/ceil(rand()*4+1)]" -extent "%[w]x%[w]" -roll +0+"%[fx:(rand()*w*0.05)+(w*0.51)]" ( +clone -blur 0x4 ) -insert 0 -composite -duplicate "%[fx:floor(rand()*3+3)*2-1]" -set option:rot "%[fx:180/n]" -virtual-pixel tile -virtual-pixel none -distort SRT "%[fx:t*360/n]" +repage -flatten -extent 100x50% ( +clone -rotate 180 ) -append +channel -virtual-pixel none -distort SRT "0.96 %[fx:rand()*360]" ( +clone -flop ) +repage -insert "%[fx:round(rand())]" -background black -flatten -brightness-contrast 20,20 -normalize dragonFire.png
*/