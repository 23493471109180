import React, { useState, useRef, useEffect } from "react";
import './Dashboard.css';
import useMediaQuery from './useMediaQuery';
import ChatContainer from './ftccore.js'
import JSZip from 'jszip';
import { setCookie, getCookie } from '../cookieUtils.js';
import VectorVault from 'vectorvault'
import { debounce } from 'lodash';

function Dashboard({ Ampersandposium, setAmpersandposium, userEmail, userKey, styles, isDarkMode, AIKeyVal, setAIKeyVal, wait, data, edit, del, gp4, apiVisible, change, description, title }) {
  const ChatContainerRef = useRef(null); // create a reference using the useRef hook
  const isMobile = useMediaQuery(768 * 2); // Adjust the breakpoint value as needed
  const [notificationMessage, setNotificationMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState('demo');
  const [siteUrl, setSiteUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [customDataVisible, setCustomDataVisible] = useState(false);
  const [customData, setCustomData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [display, setDisplay] = useState(edit);
  const [reloadData, setReloadData] = useState(false);
  const [personality, setPersonality] = useState(`Say everything like Snoop Dogg`);

  // Handle window resize
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (reloadData && !wait && Ampersandposium) {
      updateAmpersandposium(Ampersandposium);
    }
  }, [reloadData, wait, Ampersandposium]);

  useEffect(() => {
    if (!wait && Ampersandposium) {
      fetchPersonality()
    }
  }, [reloadData, wait, Ampersandposium]);

  const updateAmpersandposium = debounce((value) => {
    setAmpersandposium(value);
    setReloadData(prevState => !prevState); // Reload data
    setCookie("Ampersandposium", value);
    fetchPersonality()
  }, 0); 


  const Notification = ({ message }) => {
    // Effect to automatically hide the notification after 5 seconds
    useEffect(() => {
      let timeoutId;
      if ( notificationMessage ) {
        // Set a timer to hide the notification
        timeoutId = setTimeout(() => {
          setNotificationMessage('')
        }, 5000);
      }
      
      // Clean up the timer
      return () => clearTimeout(timeoutId);
    }, [notificationMessage]);

    // Don't render the notification if it's not supposed to be shown
    if (!notificationMessage) {
        return null;
    }

    // Render the notification
    return (
      <div style={styles.notificationStyle}>
        {message}
      </div>
    );
  };

  
  async function extractTextFromDocx(arrayBuffer) {
      const zip = new JSZip();
      const content = await zip.loadAsync(arrayBuffer);
      const docXML = await content.files["word/document.xml"].async("text");
      const parser = new DOMParser();
      const docNode = parser.parseFromString(docXML, "application/xml");

      const nsResolver = (prefix) => {
          return {
              'w': 'http://schemas.openxmlformats.org/wordprocessingml/2006/main'
          }[prefix] || null;
      };

      const textNodeResults = docNode.evaluate('//w:t', docNode, nsResolver, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);

      let textContentArray = [];
      for (let i = 0; i < textNodeResults.snapshotLength; i++) {
          textContentArray.push(textNodeResults.snapshotItem(i).textContent);
      }
      
      return textContentArray.join(" ");
  }

  async function handleFileUpload(event) {
      const file = event.target.files[0];
      setIsLoading(true);

      const reader = new FileReader();

      reader.onload = async function(event) {
        let fileContent;

        switch(file.type) {
            case 'text/plain': // .txt
            case 'text/csv':   // .csv
            case 'text/markdown': // .md
              fileContent = event.target.result;
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
                fileContent = await extractTextFromDocx(event.target.result);
                break;
            default:
                setIsLoading(false);
                alert("Unsupported file type.");
                return;
        }

        if (checkAIKey()) {
          const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
          await vectorVault.addCloud({ text : fileContent, name: file.name })
        .then(response => response.json())
        .then(data => {
        })
        .catch(error => {
            console.error("Error during fetch:", error);
        })
        .finally(() => {
            event.target.value = null;
            setIsLoading(false); // Hide spinner
            setReloadData(prevState => !prevState); // Reload data
            if (file.type.startsWith('image/')){
              alert("Text successfully extracted.")
            } else {
              alert("File uploaded successfully.")
            }
        });
      }
    };
    // Determine how to read the file based on its type
    if (file.type === 'text/plain' || file.type === 'text/csv' || file.type === 'text/markdown') {
        reader.readAsText(file);
    } else if (file.type === 'application/pdf' || 
               file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
               file.type.startsWith('image/')) {  // For all image types, including heic, jpeg, png
        reader.readAsArrayBuffer(file);
    } else {
        setIsLoading(false);
        alert("Unsupported file type.");
    }
  };

  
  async function handleCustomDataSave(customDataContent) {
      setIsLoading(true); 
      if (checkAIKey()) {
        const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
        await vectorVault.addCloud({ text : customDataContent })
      .catch(error => {
          console.error("There was an error adding your data to the cloud:", error);
      })
      .finally(() => {
          setIsLoading(false); // Hide spinner when the fetch is complete
          setCustomData('')
          setReloadData(prevState => !prevState); // Reload data
          alert("Data uploaded successfully.")
      });
    }
  }

  function checkAIKey() {
      if (!AIKeyVal || AIKeyVal.trim() === '') {
          alert("OpenAI API key required to make request");
          return false;
      }
      return true;
  }

  async function handlePersonalitySave(personalityMessage) {
      setIsLoading(true); 
      setPersonality(personalityMessage)
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
      await vectorVault.savePersonalityMessage(personalityMessage)
      alert("Personality saved successfully.") 
      setIsLoading(false); 
  }

  async function fetchPersonality() {
      const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
      try {
          const message = await vectorVault.fetchPersonalityMessage();
          setPersonality(message);
      } catch (error) {
          console.error("Error fetching personality message:", error);
      }
    }

  async function handleSiteUpload() {
      setIsLoading(true); // Show spinner

      if (checkAIKey()) {
        const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
        await vectorVault.addSite({ site : siteUrl, name: siteUrl })
        .then(response => response.json()).then(data => {
          setSiteUrl(''); // Clear the input
      }).catch(error => {
          console.error("There was an error with the fetch operation:", error);
      }).finally(() => {
          setSiteUrl('');
          setIsLoading(false); // Hide spinner
          setReloadData(prevState => !prevState); // Reload data
          alert("Site uploaded successfully.")
      });
    }
  }

  const handleDelete = () => {
    setShowModal(true); // Show the modal when the delete button is clicked
  }

  async function confirmDeletion () {
          setIsLoading(true); // Show spinner
          setShowModal(false);
          const vectorVault = new VectorVault(userEmail, Ampersandposium, userKey, AIKeyVal);
          await vectorVault.delete()
          .then(response => response.json()).then(data => {
              alert("Database deleted successfully.");
          }).catch(error => {
              console.error("There was an error with the fetch operation:", error);
          }).finally(() => {
              setIsLoading(false); // Hide spinner
              setReloadData(prevState => !prevState); // Reload data
          });
  }
  
  // Load the state from local storage when the component mounts
  useEffect(() => {
    const storedChatHistory = localStorage.getItem("chatHistory") || "[]";
    // Load the chat history from local storage
    try {
      const parsedChatHistory = JSON.parse(storedChatHistory);
      setChatHistory(parsedChatHistory);
    } catch (error) {
      console.error("Failed to parse stored chat history:", error);
    }
  }, []);

  document.addEventListener("touchstart", function() {
    if (document.body.style.zoom !== "1") {
      document.body.style.zoom = 1;
    }
  });

  return (
    <div style={styles.wrapper}>
        <div className="App" style={{...styles.body, paddingLeft: "30px", paddingRight: "30px" }}>
            <h1 style={{ ...styles.h1, textAlign: "left", fontSize: isMobile ? "1.5rem" : "2.9rem", display: "flex", alignItems: "left", marginTop: isMobile ? "50px" : "45px"}}>
                {title}
            </h1>
            <p style={{ ...styles.body, alignItems: "left", marginLeft: "0px", paddingLeft: "0px" }}> {description} </p>
            <div style={{marginTop:"20px"}}>
            {display && (
                <>
                {!edit && ( <> <div style={{marginTop: "-40px"}}></div> </>)}
                {edit && (
                  <>
                  <div style={{marginTop: "-10px"}}></div>
                    <a style={{ fontSize: ".95rem" }}>Files: </a>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                    <button className="link-button" style={{ marginLeft: "2px", fontSize: "1rem", marginBottom: "0px", backgroundColor: "transparent", border: "none", textDecoration: "underline", cursor: "pointer" }}
                        onClick={(e) => { e.preventDefault(); document.getElementById('fileInput').click(); }}>Upload</button>
                    <div>
                        <a style={{fontSize: ".95rem"}}>Site: </a>
                        <input
                            className={ isDarkMode ? 'sleek-input-dark' : 'sleek-input-light' }
                            style={{ marginTop: "15px" }}
                            type="text"
                            value={siteUrl}
                            onChange={(e) => setSiteUrl(e.target.value)}
                            placeholder="Enter website URL"
                        />
                        <button className={ isDarkMode ? 'btn-dark' : 'btn-light' } onClick={handleSiteUpload}>Submit</button>
                    </div>
                  </>
                )}
                            {/* Loading Spinner */}
                            <div className="loading-spinner" style={{ display: isLoading ? 'block' : 'none' }}>
                                <svg viewBox="0 0 50 50">
                                    <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" />
                                </svg>
                            </div>
                        <div>
                            {showModal && (
                                <div className="modal">
                                    <div className="modal-content" style={{paddingLeft: "15px", paddingTop: "10px"}}>
                                        <a>Delete every item in the database? Enter 789 to proceed:</a><br />
                                        <input 
                                            type="text" 
                                            value={inputCode}
                                            onChange={(e) => setInputCode(e.target.value)}
                                        />
                                        <button style={{marginTop: "10px"}} onClick={confirmDeletion}>Confirm</button>
                                        <button onClick={() => setShowModal(false)}>Cancel</button>
                                        <br /><br />
                                    </div>
                                </div>
                            )}
                            {edit && (
                              <div style={{marginTop: "10px", fontSize: ".95rem" }}><a>Text: </a>
                              <button className='link-button' style={{ marginTop: "0px", marginLeft: "4px", fontSize: "1rem", marginBottom: "0px", backgroundColor: "transparent", border: "none", textDecoration: "underline", cursor: "pointer"  }} onClick={() => setCustomDataVisible(!customDataVisible)}>
                                {customDataVisible ? 'Hide' : 'Show'} Field
                              </button></div>
                            )}
                            {customDataVisible && (
                                <>
                                    <textarea
                                        placeholder={`Type or paste custom data to save to the vector database here...`}
                                        id="chat-message"
                                        rows={isMobile ? 15 : 10}
                                        style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px"}}
                                        value={customData}
                                        onChange={(e) => setCustomData(e.target.value)}
                                    />
                                    <button style={{ ...styles.button, marginTop: "0px" }} onClick={() => handleCustomDataSave(customData)}>Save to database </button>
                                <br />
                                </>
                            )}
                            <button className={ isDarkMode ? 'btn-dark' : 'btn-light' } style={{marginTop: "20px", marginLeft: "0px"}} onClick={() => setIsVisible(!isVisible)}>
                                {isVisible ? 'Hide' : 'Edit'} Personality
                            </button>
                            {isVisible && (
                                <>
                                    <textarea
                                      placeholder={personality}
                                      id="chat-message"
                                      rows={isMobile ? 3 : 1}
                                      style={{ ...styles.textarea, marginTop: "5px", paddingRight: "6px", paddingLeft: "8px" }}
                                      value={personality}
                                      onChange={(e) => setPersonality(e.target.value)}
                                    />
                                    <button style={{ ...styles.button, marginTop: "0px" }} onClick={() => handlePersonalitySave(personality)}>Save Personality </button>
                                </>
                            )}  
                        </div>
                        <br></br>
                    </>
                )}
        </div>
        {notificationMessage && (
          <div style={{ marginTop: "10px" }}>
            <Notification
              message={notificationMessage}
            />
          </div>
        )}

      <ChatContainer
        isDarkMode={isDarkMode}
        ref={ChatContainerRef} // pass the reference as a prop
        isMobile={isMobile}
        chatHistory={chatHistory}
        setChatHistory={setChatHistory}
        windowWidth={windowWidth}
        Ampersandposium={Ampersandposium} // vault
        display={display}
        reloadData={reloadData}
        userEmail={userEmail} // email
        userKey={userKey} // api
        AIKeyVal={AIKeyVal} // api
        setAIKeyVal={setAIKeyVal} // api
        wait={wait}
        styles={styles}
        edit={edit}
        del={del}
        data={data}
        apiVisible={apiVisible}
        change={change}
        setNotificationMessage={setNotificationMessage}
        gpt4={gp4}
      />
    </div>
  </div>
  );
}

export default Dashboard;



