const BASE_URL = 'https://register.vectorvault.io';

export async function secureSessionStore(email, key) {
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('key', key);

    try {
        const response = await fetch(`${BASE_URL}/secure_session_store`, {
            method: 'POST',
            headers: {
                'Authorization': 'unexpected_authorization_code',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData
        });

        
        const data = await response.json();
        console.log('Success:', data);
        return data; // Returning the response data
    } catch (error) {
        console.error('Error:', error);
        throw error; // Throwing the error to be handled by the caller
    }
};

export async function secureSessionRetrieve(email) {
    const headers = {
      'Authorization': 'unexpected_authorization_code',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const data = new URLSearchParams({
        email: email
    });
    
    try {
        const response = await fetch(`${BASE_URL}/secure_session_retrieve`, {
            method: 'POST',
            headers: headers,
            body: data
        });
        
    if (response.status === 200) {
      const turn = await response.json();
      return turn.key;
    } else {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      return { error: 'Request failed with status code: ' + response.status + ', Message: ' + errorText };
    }
  } catch (error) {
    console.error('Request error:', error);
    return { error: error.message };
  }
}

export async function secureSessionDelete(email) {
    const headers = {
      'Authorization': 'unexpected_authorization_code',
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const data = new URLSearchParams({
        email: email
    });
    
    try {
        const response = await fetch(`${BASE_URL}/secure_session_delete`, {
            method: 'POST',
            headers: headers,
            body: data
        });
        
    if (response.status === 200) {
        console.log(await response.json())
      return ;
    } else {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      return { error: 'Request failed with status code: ' + response.status + ', Message: ' + errorText };
    }
  } catch (error) {
    console.error('Request error:', error);
    return { error: error.message };
  }
}
